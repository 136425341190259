<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isManySelect="true"
    >
      <template #searchSlot>
        <v-input
          label="姓名"
          placeholder="请输入姓名"
          v-model="searchParam.realName"
        ></v-input>
        <v-input
          label="手机号"
          placeholder="请输入手机号"
          v-model="searchParam.mobileNum"
        ></v-input>
        <v-select
          clearable
          :options="auditStatusList"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="状态"
        />
      </template>
      <template #batchSlot="scope">
        <v-button
          v-if="searchParam.auditStatus == 0"
          text="批量审核"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="toExamine(scope.row)"
        />
      </template>
    </v-list>
    <!-- 审核弹框 -->
    <v-dialog
      title="审核"
      v-model="batchAuditDiglog"
      width="50%"
      @confirm="audit"
    >
      <div class="recordSportDialog">
        <el-form label-width="100px">
          <el-form-item label="审核结果">
            <v-select :options="handleStatus" v-model="batchAuditAuditStatus" />
          </el-form-item>
          <el-form-item label="备注">
            <v-input
              type="textarea"
              placeholder="请输入备注"
              v-model="auditRemarks"
            ></v-input>
          </el-form-item>
        </el-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getHouseOwnerAuditListUrl,
  batchApproveHouseOwnerAuditUrl,
  getHouseOwnerAuditDetailUrl,
  examineHouseOwnerAuditUrl,
} from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { auditStatusOptions, auditStatusMap, auditStatusOps } from "./map.js";
export default {
  name: "housePropertyOwnerExamine",
  data() {
    return {
      batchAuditAuditStatus: null,
      auditRemarks: null,
      auditStatusList: auditStatusOptions,
      tableUrl: getHouseOwnerAuditListUrl,
      searchParam: {
        realName: null,
        mobileNum: null,
        auditStatus: 0,
      },
      batchAuditDiglog: false,
      batchSendIdList: [],
      headers: [
        {
          prop: "houseName",
          label: "房号",
        },
        {
          prop: "realName",
          label: "姓名",
        },
        {
          prop: "mobileNum",
          label: "手机号",
        },
        {
          prop: "houseownerCertPhoto",
          label: "房产证图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "identity",
          label: "是否产权人",
          formatter: (row, prop) => {
            if (row.identity == 0) {
              return "产权人本人";
            } else if (row.identity == 1) {
              return "产权人配偶";
            }
          },
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < auditStatusOptions.length; i++) {
              if (auditStatusOptions[i].value == row.auditStatus) {
                return auditStatusOptions[i].label;
              }
            }
          },
        },
        {
          prop: "auditRemarks",
          label: "备注",
          formatter: (row, prop) => {
            return row.auditRemarks ? row.auditRemarks : "-";
          },
        },
        {
          prop: "createTime",
          label: "提交时间",
        },
      ],
      obj: {},
    };
  },
  computed: {
    handleStatus() {
      let arr = JSON.parse(JSON.stringify(auditStatusOptions));
      arr.splice(0, 2);
      return arr;
    },
  },
  methods: {
    toExamine(item) {
      this.batchAuditDiglog = true;
      this.obj = item;
      console.log(item);
    },
    batchSend(list) {
      //批量审核通过
      const { data, ids } = list;
      this.$confirm("是否确认审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: ids,
        };
        this.$axios
          .post(`${batchApproveHouseOwnerAuditUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.batchAuditDiglog = false;
              this.batchAuditAuditStatus = null;
              this.batchSendIdList = [];
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    audit() {
      // 审核
      if (this.batchAuditAuditStatus == null) {
        this.$message.error("请选择审核结果！");
        return;
      }
      // if (this.auditRemarks == "") {
      //   this.$message.error("请输入备注！");
      //   return;
      // }
      let params = {
        id: this.obj.id,
        auditStatus: this.batchAuditAuditStatus,
        auditRemarks: this.auditRemarks,
      };
      this.$axios.post(`${examineHouseOwnerAuditUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.batchAuditDiglog = false;
          this.batchAuditAuditStatus = null;
          this.auditRemarks = "";
          this.$message.success("操作成功");
          this.$refs.list.search();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
